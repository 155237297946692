import {default as axios} from "axios";
import {SHIPMENT_METHODS_URL, SHIPMENT_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class ShipmentApiClient {
    downloadSpedLabelsAsZip(data, successCallback, errorCallback, finallyCallback) {
        axios.post(SHIPMENT_URL + '/sped/labels', data, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN),
                'Accept': 'application/zip'
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    generateSpedLabel(id, successCallback, errorCallback, finallyCallback) {
        axios.get(SHIPMENT_URL + `/${id}/sped/labels`, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN),
                'Accept': 'application/pdf'
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    getShipmentMethods(successCallback, errorCallback, finallyCallback = () => {}) {
        axios.get(SHIPMENT_METHODS_URL, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    importShipmentMethods(formData, successCallback, errorCallback, finallyCallback = () => {}) {
        axios.post(SHIPMENT_URL + '/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getCouriers(successCallback, errorCallback, finallyCallback) {
        axios.get(SHIPMENT_URL + '/couriers', {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getShipmentMethod(methodId, successCallback, errorCallback, finallyCallback) {
        axios.get(SHIPMENT_METHODS_URL + `/${methodId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    addShipmentMethod(data, successCallback, errorCallback, finallyCallback) {
        axios.post(SHIPMENT_METHODS_URL, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    deleteShipmentMethod(methodId, successCallback, errorCallback, finallyCallback) {
        return axios.delete(SHIPMENT_METHODS_URL + `/${methodId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    updateShipmentMethod(methodId, data, successCallback, errorCallback, finallyCallback) {
        axios.put(SHIPMENT_METHODS_URL + `/${methodId}`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(() => {
            successCallback()
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }
}

export const shipmentApiClient = new ShipmentApiClient();