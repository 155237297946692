<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-row>
        <v-col>
          <v-btn
              block
              color="primary"
              elevation="2"
              large
              @click="exportEmailTemplates"
          >
            Exportuj metody do pliku
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              block
              color="primary"
              elevation="2"
              large
              :loading="isSelecting"
              @click="handleFileImport"
          >
            Importuj metody z pliku
          </v-btn>
          <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link :to="{ name: 'mails-new'}">
            <v-btn>
              Dodaj nowy wzór maila
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="templates"
              :search="search"
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Szukaj"
                  class="mx-4"
              ></v-text-field>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    Akcje
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                          @click="editMailTemplate(item)"
                      >Edytuj
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                          @click="deleteTemplate(item.id)"
                      >Usuń
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>


import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {mailsApiClient} from "@/api/mails.api.client";
import {saveAs} from "file-saver";

export default {
  name: 'MailTemplatesView',

  components: {SpinnerComponent, ErrorDialogComponent},

  data() {
    return {
      isSelecting: false,

      search: '',
      templates: [],
      headers: [
        {text: "Id", value: 'id'},
        {text: "Nazwa", value: 'name'},
        {text: "Typ", value: 'type'},
        {text: "Opublikowno", value: 'published'},
        {text: "Dodano", value: 'addedAt'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.getTemplates();
  },

  methods: {
    getTemplates() {
      this.loading = true;
      mailsApiClient.getTemplates(response => {
        this.templates = response.data.map(template => {
          return {
            id: template.id,
            name: template.name,
            type: template.type,
            design: template.design,
            published: template.published,
            addedAt: template.addedAt,
            html: template.html
          }
        });
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd podczas pobierania wzorów!";
      }, () => this.loading = false)
    },

    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true});

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.importEmailTemplates(e.target.files[0])
    },

    exportEmailTemplates() {
      mailsApiClient.getTemplates((response) => {
        const data = new Blob([JSON.stringify(response.data)])
        saveAs(data, 'email_templates.json');
      }, (error) => {
        console.error(error);
      }, () => {
      })
    },

    importEmailTemplates(jsonFile) {
      const formData = new FormData();
      formData.append("file", jsonFile);
      mailsApiClient.importEmailTemplates(formData, () => {
        this.getTemplates();
      }, (error) => {
        console.error(error);
      }, () => {
        this.$refs.uploader.value = '';
      })
    },

    editMailTemplate(item) {
      this.$router.push({name: 'mails-edit', params: {id: item.id, templateProp: item}})
    },

    deleteTemplate(id) {
      mailsApiClient.removeTemplate(id, () => {
        this.getTemplates();
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd podczas usuwania wzoru!";
      })
    }
  }
};
</script>