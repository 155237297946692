<template>
  <v-container
      class="px-0"
      fluid
  >
    <v-row>
      <v-col>
        <v-checkbox
            v-model="timeLimit"
            @change="changedTimeLimit"
            label="Limit czasowy"
        ></v-checkbox>
        <div v-if="timeLimit">
          <v-date-picker
              v-model="dates"
              @input="dateChange"
              range
          ></v-date-picker>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
            v-model="valueLimit"
            label="Limit wartości koszyka"
            @change="changedValueLimit"
        ></v-checkbox>
        <div v-if="valueLimit">
          <v-text-field
              label="Minimum"
              v-model="local.limitations.valueLimit.minimalValue"
          >
          </v-text-field>
          <v-text-field
              label="Maximum"
              v-model="local.limitations.valueLimit.maximumValue"
          >
          </v-text-field>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {computed} from "vue";

export default {
  name: 'GenericLimits',
  props: {
    modelValue: Object,
  },

  computed: {
    local() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  components: {},

  emits: ['update:modelValue'], // The component emits an event

  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  created() {
    if (!!this.modelValue.limitations.timeLimit === true) {
      this.timeLimit = true;
      this.dates = [
        this.modelValue.limitations.timeLimit.startDate,
        this.modelValue.limitations.timeLimit.endDate
      ]
    }
    if (!!this.modelValue.limitations.valueLimit === true) {
      this.valueLimit = true
    }
  },

  methods: {
    dateChange() {
      if (this.timeLimit) {
        if (this.dates.length > 1) {
          this.local.limitations.timeLimit.startDate = this.dates[0];
          this.local.limitations.timeLimit.endDate = this.dates[1];
        }
      }
    },
    changedTimeLimit(event) {
      if (event) {
        this.local.limitations.timeLimit = {};
      } else {
        this.local.limitations.timeLimit = null;
      }
    },
    changedValueLimit(event) {
      if (event) {
        this.local.limitations.valueLimit = {
          minimalValue: 0,
          maximumValue: 0
        }
      } else {
        this.local.limitations.valueLimit = null;
      }
    }
  },

  data() {
    return {
      dates: [],
      timeLimit: false,
      valueLimit: false
    }
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
