<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <v-container>
      <div class="fcanvas" ref="fcanvas">
        <canvas ref="canvas" id="can"></canvas>
      </div>
      <v-row>
        <v-col>
          <v-card>
            <v-btn block
                   color="success"
                   elevation="2"
                   large
                   @click="zoomIn()">
              <label>Powiekszenie</label>
            </v-btn>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-btn block
                   color="success"
                   elevation="2"
                   large
                   @click="zoomOut()">
              <label>Pomniejszenie</label>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-file-input
          @change="addImageLayer($event)"
          accept="image/*"
          label="Dodaj plik"
      ></v-file-input>
      <v-row>
        <v-col>
          <v-text-field
              label="Width"
              v-model="emptyPageWidth"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
              label="Height"
              v-model="emptyPageHeight"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn block
                 color="success"
                 elevation="2"
                 large
                 @click="addEmptyPage()">
            <label>Dodaj białą stronę</label>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-btn block
                   color="success"
                   elevation="2"
                   large
                   @click="addOrderNumberCover()">
              <label>Dodaj znacznik zamówienia [OKŁADKA]</label>
            </v-btn>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-btn block
                   color="success"
                   elevation="2"
                   large
                   @click="addOrderNumberInside()">
              <label>Dodaj znacznik zamówienia [ŚRODEK]</label>
            </v-btn>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-btn block
                   color="success"
                   elevation="2"
                   large
                   @click="addTextLayer()">
              <label>Dodaj warstwę tekstową</label>
            </v-btn>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-btn block
                   color="success"
                   elevation="2"
                   large
                   @click="addDedicationLayer()">
              <label>Dodaj warstwę dedykacji</label>
            </v-btn>
          </v-card>
        </v-col>
        <v-col>
          <v-btn block
                 color="success"
                 elevation="2"
                 large
                 @click="addGrid()">
            <label>Włącz siatkę</label>
          </v-btn>
          <div>
            <v-text-field
                type="number"
                label="Zmiana gęstości siatki"
                @change="gridGranularityChange($event)"
                :value="gridGranularity"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-expansion-panels>
        <v-subheader>Warstwy</v-subheader>
        <v-expansion-panel
            v-for="item in layers"
            :key="item.name"
        >
          <v-expansion-panel-header>
            <v-container>
              <v-row>
                <v-col
                    cols="2"
                >
                  <v-select
                      v-if="item.type === 'image'"
                      label="Oznacz jako template"
                      v-model="item.template"
                      :items="availableTemplates"
                      clearable
                      @change="switchUserImage(item, $event)"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-btn block
                         color="primary"
                         elevation="2"
                         large
                         @click="removeItem(item)">
                    <v-icon
                        right
                        dark>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col>{{ item.name || 'TEXT' }}</v-col>
                <v-col>
                  <v-btn block
                         color="primary"
                         elevation="2"
                         large
                         @click="moveBackward(item)">
                    <v-icon right
                            dark>
                      mdi-arrow-up
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block
                         color="primary"
                         elevation="2"
                         large
                         @click="moveForward(item)">
                    <v-icon right
                            dark>
                      mdi-arrow-down
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block
                         color="primary"
                         elevation="2"
                         large
                         @click="lock(item)">
                    <v-icon
                        dark
                        right
                        v-if="item.locked"
                    >
                      mdi-lock
                    </v-icon>
                    <v-icon
                        dark
                        right
                        v-if="!item.locked"
                    >
                      mdi-lock-open
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block
                         color="primary"
                         elevation="2"
                         large
                         @click="visible(item)">
                    <v-icon
                        dark
                        right
                        v-if="item.visible"
                    >
                      mdi-eye-off
                    </v-icon>
                    <v-icon
                        dark
                        right
                        v-if="!item.visible"
                    >
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container v-if="item.text!==undefined">
              <v-row>
                <v-col>
                  <v-slider
                      v-if="item.name === 'DEDICATION'"
                      v-model="item.maxLines"
                      label="Ilośc linii"
                      thumb-label="always"
                      step="1"
                      min="1"
                  >
                  </v-slider>

                  <v-slider
                      v-model="item.lineHeight"
                      label="Line height"
                      thumb-label="always"
                      @change="lineHeightChanged($event, item)"
                      step="0.01"
                      min="0.00"
                  >
                    <template v-slot:prepend>
                      <v-icon
                          @click="decrementLineHeight(item)"
                      >
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon
                          @click="incrementLineHeight(item)"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider>

                  <v-slider
                      v-model="item.fontSize"
                      label="Font size"
                      thumb-label="always"
                      @change="fontSizeChanged($event, item)"
                      step="1"
                      max="500"
                      min="1"
                  >
                    <template v-slot:prepend>
                      <v-icon
                          @click="decrementFontSize(item)"
                      >
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon
                          @click="incrementFontSize(item)"
                      >
                        mdi-plus
                      </v-icon>
                    </template>

                  </v-slider>

                  <v-select
                      label="Wybierz font"
                      v-model="item.fontFamily"
                      @change="changeFont($event, item)"
                      :items="availableFonts"
                      item-text="name"
                      item-value="name"
                  ></v-select>

                  <v-file-input
                      label="Dodaj nowy font"
                      @change="addFont($event)"
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-color-picker
                      v-model="item.fill"
                      dot-size="25"
                      swatches-max-height="200"
                      @change="colorChanged($event, item)"
                  ></v-color-picker>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" class="my-4 fluid">
                  <v-select
                      label="Wybierz dedykowną płęć"
                      v-model="item.gender"
                      :items="genders"
                      item-text="desc"
                      item-value="mapping"
                      chips
                  ></v-select>
                </v-col>
                <v-col cols="4" class="my-4 fluid">
                  <v-select
                      label="Wybierz metodę wyrównania tekstu"
                      v-model="item.textAlign"
                      :items="textAlignOptions"
                      @change="textAlignChanged($event, item)"
                      chips
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea solo @change="textChanged($event, item)" :value=item.text></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-container>
        <v-row>
          <v-col>
            <router-link :to="{ name: 'pages', params: { kidCount: this.kidCount }  }">
              <v-btn block
                     color="primary"
                     elevation="2"
                     large>
                Wróc bez zmian
              </v-btn>
            </router-link>
          </v-col>
          <v-col>
            <v-btn
                block
                color="primary"
                elevation="2"
                large
                @click="saveAndClose()">Save and close
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import {fabric} from 'fabric';
import {toRaw} from "@vue/reactivity";
import SpinnerComponent from "@/components/SpinnerComponent";
import {metadataApiClient} from "@/api/metadata.api.client";
import {fontApiClient} from "@/api/font.api.client";

let canvas;

export default {
  name: 'DesignerView',
  components: {SpinnerComponent},
  props: {
    productId: String,
    page: String,
    updating: Boolean,
    kidCount: Number
  },

  data() {
    return {
      loading: false,
      layers: [],
      genders: [
        {
          mapping: 'm',
          desc: "chłopczyk"
        },
        {
          mapping: 'f',
          desc: "dziewczynka"
        },
        {
          mapping: 'f_f',
          desc: "dwie dziewczynki"
        },
        {
          mapping: 'f_m',
          desc: "dziewczynka i chłopczyk"
        },
        {
          mapping: 'm_m',
          desc: "dwóch chłopców"
        }
      ],
      availableFonts: [],
      gridGranularity: 50,
      emptyPageWidth: 2362,
      emptyPageHeight: 2362,
      availableTemplates: [],

      textAlignOptions: [
        'center', 'right', 'justify', 'justify-left', 'justify-center', 'justify-right'
      ]
    }
  },

  created() {
    this.$watch(
        () => this.$route.params,
        () => {
        },
        {immediate: true}
    )

    for (let i = 0; i < this.kidCount; i++) {
      this.availableTemplates.push(`template_${i}`)
    }
  },

  methods: {
    decrementLineHeight(item) {
      item.set({lineHeight: item.lineHeight - 0.01});
      canvas.renderAll();
    },

    incrementLineHeight(item) {
      item.set({lineHeight: item.lineHeight + 0.01});
      canvas.renderAll();
    },

    decrementFontSize(item) {
      item.set({fontSize: item.fontSize - 1});
      canvas.renderAll();
    },

    incrementFontSize(item) {
      item.set({fontSize: item.fontSize + 1});
      canvas.renderAll();
    },

    zoomOut() {
      canvas.setZoom(canvas.getZoom() - 0.1);
      canvas.setWidth(this.originalWidth * canvas.getZoom());
      canvas.setHeight(this.originalHeight * canvas.getZoom());
      canvas.requestRenderAll();
    },

    zoomIn() {
      canvas.setZoom(canvas.getZoom() + 0.1);
      canvas.setWidth(this.originalWidth * canvas.getZoom());
      canvas.setHeight(this.originalHeight * canvas.getZoom());
      canvas.requestRenderAll();
    },

    addImageLayer(file) {
      this.loading = true;
      const that = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          this.imageBase64 = reader.result;

          const imgEl = fabric.util.createImage();
          imgEl.src = this.imageBase64;

          fabric.Image.fromURL(imgEl.src, (img) => {
            if (that.layers.length === 0) {
              that.originalWidth = imgEl.width;
              that.originalHeight = imgEl.height;
              canvas.setWidth(imgEl.width);
              canvas.setHeight(imgEl.height);
            }

            img.locked = false;
            img.visible = true;
            img.name = file.name;
            img.layerType = 'IMAGE'
            canvas.add(img);

            this.loading = false;
            this.layers = this.getLayers();
          });
        }
      };
    },

    getLayers() {
      return canvas.getObjects().filter(o => o.type !== 'line');
    },

    addTextLayer() {
      let textLayer = new fabric.Textbox('template', {
        styles: {},
        left: 0,
        top: 0,
        fill: '#000000FF',
        fontSize: 60,
        textAlign: 'justify',
        lockScalingY: true
      });
      textLayer.locked = false;
      textLayer.name = 'TEXT';
      textLayer.layerType = 'TEXT';
      textLayer.visible = true;

      canvas.add(textLayer);
      this.layers = this.getLayers();
      canvas.requestRenderAll();
    },

    addDedicationLayer() {
      let textLayer = new fabric.Textbox('{dedication}', {
        styles: {},
        left: 0,
        top: 0,
        fill: '#000000FF',
        fontSize: 60,
        textAlign: 'justify',
        lockScalingY: true
      });
      textLayer.locked = false;
      textLayer.name = "DEDICATION";
      textLayer.layerType = "DEDICATION";
      textLayer.maxLines = 4;
      textLayer.visible = true;
      textLayer.isDedication = true;

      canvas.add(textLayer);
      this.layers = this.getLayers();
      canvas.requestRenderAll();
    },

    addEmptyPage() {
      this.originalWidth = this.emptyPageWidth;
      this.originalHeight = this.emptyPageHeight;
      canvas.setWidth(this.emptyPageWidth);
      canvas.setHeight(this.emptyPageHeight);

      const square = new fabric.Rect({
        width: parseInt(this.emptyPageWidth),
        height: parseInt(this.emptyPageHeight),
        fill: '#ffffff'
      });
      canvas.add(square);
      canvas.renderAll();
      console.log(canvas);
      this.layers = this.getLayers();
    },

    moveForward(item) {
      canvas.bringForward(toRaw(item));
      this.layers = this.getLayers();
    },

    moveBackward(item) {
      canvas.sendBackwards(toRaw(item));
      this.layers = this.getLayers();
    },

    lock(item) {
      item['lockMovementX'] = !item['lockMovementX'];
      item['lockMovementY'] = !item['lockMovementY'];
      item['selectable'] = !item['selectable'];
      item.locked = !item.locked;
    },

    visible(item) {
      if (item.visible) {
        item.visible = false;
        item.opacity = 0;
      } else {
        item.visible = true;
        item.opacity = 1;
      }
      canvas.renderAll();
    },

    switchUserImage(item, template) {
      item.template = template;
      canvas.renderAll();
    },

    removeItem(item) {
      const rawItem = toRaw(item);
      canvas.remove(rawItem);
      this.layers = this.getLayers();

      canvas.renderAll();
    },

    generateMetadataPage() {
      canvas.originalHeight = this.originalHeight;
      canvas.originalWidth = this.originalWidth;
      const lines = canvas.getObjects().filter(o => o.type === 'line');
      lines.forEach(line => canvas.remove(line));
      return canvas.toJSON(['cacheKey', 'template', 'gender', 'name', 'visible', 'locked', 'width', 'height', 'viewportTransform', 'vptCoords', 'originalHeight', 'originalWidth', 'lockMovementX', 'lockMovementY', 'lockScalingY', 'selectable', 'maxLines', 'layerType']);
    },

    saveAndClose() {
      if (this.updating === true) {
        this.updateBookPage(this.generateMetadataPage());
      } else {
        this.addNewBookPage(this.generateMetadataPage());
      }
    },

    addNewBookPage(jsonData) {
      this.loading = true;
      metadataApiClient.addNewBookPage(this.productId, jsonData,
          () => {
            this.$router.push({name: 'pages', params: {productId: this.productId, kidCount: this.kidCount}});
          }, (err) => {
            console.error('Failed to save data', err);
          }, () => this.loading = false);
    },

    ////////////------------- UPDATE --------///////////////
    updateBookPage(jsonData) {
      this.loading = true;
      metadataApiClient.updateBookPage(this.productId, this.page, jsonData,
          () => {
            this.$router.push({name: 'pages', params: {productId: this.productId, kidCount: this.kidCount}});
          },
          (err) => {
            console.error('Failed to save data', err);
          }, () => {
            this.loading = false;
          });
    },

    ////////////------------- OTHER --------///////////////

    colorChanged($event, item) {
      const color = $event.target.value;
      item.set({fill: color});
      canvas.renderAll();
    },

    textChanged(changedText, item) {
      item.set({text: changedText});
      canvas.renderAll();
    },

    textAlignChanged(option, item) {
      item.set({textAlign: option});
      canvas.renderAll();
    },

    fontSizeChanged(changedSize, item) {
      item.set({fontSize: changedSize});
      canvas.renderAll();
    },

    lineHeightChanged(changedHeight, item) {
      item.set({lineHeight: changedHeight});
      canvas.renderAll();
    },

    addFont(file) {
      let formData = new FormData();
      formData.append("file", file);

      fontApiClient.addFont(formData, (response) => {
        this.preloadFont(response.data)
            .then(font => {
              this.availableFonts.push(font.family);
              document.fonts.add(font);
            })
      }, (error) => {
        console.error('Could not upload the files', error);
      }, () => {
      });
    },

    changeFont(font, item) {
      item.set({fontFamily: font});
      canvas.renderAll();
    },

    preloadFont(font) {
      return fontApiClient.preloadFont(font.name, response => {
        const loadingFont = new FontFace(font.name, response.data, {
          style: 'normal',
          weight: 'normal'
        });
        return loadingFont.load();
      }, error => {
        console.error(`Error for ${font.name} `, error);
      }, () => {
      });
    },

    preloadFonts(fonts, chainCallback) {
      const preloadPromises = fonts.map(font => {
        return this.preloadFont(font)
      });
      Promise.allSettled(preloadPromises)
          .then(results => {
            results
                .filter(result => result.status === "fulfilled")
                .filter(result => result.value !== undefined)
                .map(result => result.value)
                .forEach(font => {
                  this.availableFonts.push(font.family);
                  document.fonts.add(font);
                });
            chainCallback();
          })
          .catch(e => {
            console.error(e);
          })
    },

    changeGender($event, item) {
      if ($event.value === "") {
        item.gender = null;
      } else {
        item.gender = $event.value;
      }
    },

    /*
    --------- SERVICES -------
     */

    loadProductMetadataPage() {
      this.loading = true;
      let that = this;
      metadataApiClient.getProductMetadataPage(this.productId, this.page,
          (response) => {
            canvas.loadFromJSON(response.data, () => {
              canvas.setWidth(response.data.width);
              canvas.setHeight(response.data.height);

              that.originalWidth = response.data.originalWidth;
              that.originalHeight = response.data.originalHeight;

              canvas.renderAll.bind(canvas);
              that.layers = this.getLayers();
            }, () => {
              canvas.renderAll.bind(canvas);
            });
          }, (error) => {
            console.error('Could not upload the files' + error);
          }, () => this.loading = false);
      canvas.renderAll();
    },

    addGrid() {
      // create grid
      for (let i = 0; i < (this.originalWidth / this.gridGranularity); i++) {
        canvas.add(new fabric.Line([i * this.gridGranularity, 0, i * this.gridGranularity, this.originalHeight],
            {type: 'line', stroke: '#ccc', selectable: false}));
        canvas.add(new fabric.Line([0, i * this.gridGranularity, this.originalWidth, i * this.gridGranularity],
            {type: 'line', stroke: '#ccc', selectable: false}))
      }
    },

    gridGranularityChange(granularity) {
      if (granularity < 10) {
        granularity = 10;
      }
      const lines = canvas.getObjects().filter(o => o.type === 'line');
      lines.forEach(line => canvas.remove(line));
      this.gridGranularity = granularity;

      // create grid
      for (let i = 0; i < (this.originalWidth / this.gridGranularity); i++) {
        canvas.add(new fabric.Line([i * this.gridGranularity, 0, i * this.gridGranularity, this.originalHeight],
            {type: 'line', stroke: '#ccc', selectable: false}));
        canvas.add(new fabric.Line([0, i * this.gridGranularity, this.originalWidth, i * this.gridGranularity],
            {type: 'line', stroke: '#ccc', selectable: false}))
      }
    },

    addOrderNumberCover() {
      this.loading = true;
      fabric.Image.fromURL(process.env.VUE_APP_ENV + '/rozkladowka.png', (img) => {
        img.locked = true;
        img.visible = true;
        img.name = 'ORDER_NUMBER_IMAGE';
        img.layerType = 'ORDER_NUMBER_IMAGE';
        img.scaleToHeight(canvas.originalHeight);
        img.scaleToWidth(canvas.originalWidth);
        canvas.add(img);

        let textLayer = new fabric.Textbox('{order_visible_id}', {
          styles: {},
          left: (canvas.originalWidth / 2) + 40,
          top: 20,
          fill: '#000000FF',
          fontSize: 50,
          textAlign: 'justify',
          lockScalingY: true
        });
        textLayer.locked = true;
        textLayer.name = 'ORDER_NUMBER_COVER';
        textLayer.layerType = 'ORDER_NUMBER_COVER';
        textLayer.visible = true;

        canvas.add(textLayer);

        this.loading = false;
        this.layers = this.getLayers();
      }, {crossOrigin: 'anonymous'});
    },

    addOrderNumberInside() {
      let textLayer = new fabric.Textbox('{order_visible_id}', {
        styles: {},
        left: (canvas.originalWidth / 2),
        top: canvas.originalHeight - 50,
        fill: '#000000FF',
        fontSize: 30,
        textAlign: 'justify',
        lockScalingY: true
      });
      textLayer.name = 'ORDER_NUMBER_INSIDE';
      textLayer.layerType = 'ORDER_NUMBER_INSIDE';
      textLayer.locked = true;
      textLayer.visible = true;

      canvas.add(textLayer);
      this.layers = this.getLayers();
    }
  },

  mounted() {
    this.loading = true;
    const ref = this.$refs.canvas;

    canvas = new fabric.Canvas(ref, {
      width: 500,
      height: 300
    });

    fabric.Object.prototype.objectCaching = false;

    canvas.preserveObjectStacking = true;
    canvas.renderOnAddRemove = true;
    canvas.layers = [];

    fontApiClient.getFonts((response) => {
      this.preloadFonts(response.data, () => {
        if (this.updating === true || this.updating === undefined) {
          this.loadProductMetadataPage();
        } else {
          this.loading = false;
        }
      });
    }, (error) => {
      console.error('Could not upload the files', error);
    }, () => {
    });
  }
}

</script>

<style>
textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}


.example-form {
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.fonts-container {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.textarea-container {
  width: 100%;
  resize: vertical;
}

.fcanvas {
  max-width: 50%;
  z-index: 10;
}

</style>
