<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        app>
      <v-list-item>
        <v-list-item-title class="text-h6">
          Bookhero Admin Console
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <div v-for="(item, i) in getVisibleItems()"
             :key="i"
        >

          <v-list-item
              v-if="emptyItems(item)"
              :key="i"
              :to="item.to"
              class="v-list-item"
          >
            <v-list-item-title v-text="item.title"/>
          </v-list-item>

          <v-list-group
              v-else
              :key="item.title"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="child in getChildren(item)"
                :key="child.title"
                :to="child.to"
            >
              <v-icon>{{ child.icon }}</v-icon>
              <v-list-item-title class="pa-2">{{ child.title }}</v-list-item-title>
            </v-list-item>

          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>Bookhero Admin Console</v-app-bar-title>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

import {authUtils} from "@/api/auth.utils";

export default {
  components: {},
  data: () => ({
    drawer: null,
    items: [
      {
        title: 'Profil',
        items: [
          {
            title: 'Informacje',
            value: 'profile',
            icon: 'mdi-account',
            to: '/profile',
            visible: true
          }
        ],
        visible: true
      },
      {
        title: 'Książeczki',
        visible: authUtils.requireRoles(['admin', 'internal', 'contractor']),
        items: [
          {
            title: 'Wszystkie',
            value: 'books',
            icon: 'mdi-view-dashboard',
            to: '/books/all',
            visible: true
          },
          {
            title: 'Przypisane do mnie',
            value: 'booksMy',
            icon: 'mdi-view-dashboard',
            to: '/books/my',
            visible: true
          },
        ]
      },
      {
        title: 'Zamówienia',
        visible: authUtils.requireRoles(['admin', 'internal']),
        items: [
          {
            title: 'Wszystkie',
            value: 'orders',
            icon: 'mdi-view-dashboard',
            to: '/orders/all',
            visible: true
          },
          {
            title: 'Nowe',
            value: 'orders',
            icon: 'mdi-view-dashboard',
            to: '/orders/new',
            visible: true
          },
          {
            title: 'Opłacone',
            value: 'paid-orders',
            icon: 'mdi-view-dashboard',
            to: '/orders/paid',
            visible: true
          },
          {
            title: 'Do zatwierdzenia',
            value: 'approval-orders',
            icon: 'mdi-view-dashboard',
            to: '/orders/approval',
            visible: true
          },
          {
            title: 'Do druku',
            value: 'printable-orders',
            icon: 'mdi-view-dashboard',
            to: '/orders/printable',
            visible: true
          },
          {
            title: 'Zakończone',
            value: 'finished-orders',
            icon: 'mdi-view-dashboard',
            to: '/orders/finished',
            visible: true
          },
          {
            title: 'Anulowane',
            value: 'canceled-orders',
            icon: 'mdi-view-dashboard',
            to: '/orders/canceled',
            visible: true
          }
        ]
      },
      {
        title: 'Produkty',
        visible: authUtils.requireRoles(['admin', 'internal']),
        items: [
          {
            title: 'Wszystkie',
            value: 'products',
            icon: 'mdi-image',
            to: '/products/all',
            visible: true
          },
          {
            title: 'Opublikowane',
            value: 'published-products',
            icon: 'mdi-image',
            to: '/products/published',
            visible: true
          },
          {
            title: 'Drafty',
            value: 'drafted-products',
            icon: 'mdi-image',
            to: '/products/draft',
            visible: true
          },
          {
            title: 'Dodaj nowy',
            value: 'new-product',
            icon: 'mdi-image',
            to: '/products/new',
            visible: true
          }
        ]
      },
      {
        title: 'Kody rabatowe',
        visible: authUtils.requireRoles(['admin', 'internal']),
        items: [
          {
            title: 'Allegro',
            value: 'allegro',
            icon: 'mdi-folder',
            to: '/vouchers/group/allegro',
            visible: true
          },
          {
            title: 'Katalog marzeń',
            value: 'dream-catalog',
            icon: 'mdi-folder',
            to: '/vouchers/group/dream-catalog',
            visible: true
          },
          {
            title: 'Grupon',
            value: 'grupon',
            icon: 'mdi-folder',
            to: '/vouchers/group/grupon',
            visible: true
          },
          {
            title: 'Inne',
            value: 'other',
            icon: 'mdi-folder',
            to: '/vouchers/group/other',
            visible: true
          }
        ]
      },
      {
        title: 'Obsługa dostaw',
        visible: authUtils.requireRoles(['admin', 'internal']),
        items: [
          {
            title: 'Metody dostawy',
            value: 'shipment-methods',
            icon: 'mdi-folder',
            to: '/shipment-methods/all',
            visible: true
          },
          {
            title: 'Dodaj nową metode dostawy',
            value: 'new-shipment-method',
            icon: 'mdi-folder',
            to: '/shipment-methods/new',
            visible: true
          }
        ]
      },
      {
        title: 'Maile',
        visible: authUtils.requireRoles(['admin', 'internal']),
        items: [
          {
            title: 'Wzory',
            value: 'mails-all',
            icon: 'mdi-mail',
            to: '/mails-all',
            visible: true
          },
          {
            title: 'Dodaj nowy newsletter',
            value: 'newsletter-new',
            icon: 'mdi-mail',
            to: '/newsletter-new',
            visible: true
          },
          {
            title: 'Newslettery',
            value: 'newsletter-all',
            icon: 'mdi-mail',
            to: '/newsletter-all',
            visible: true
          },
          {
            title: 'Lista mailingowa',
            value: 'mailing-all',
            icon: 'mdi-mail',
            to: '/mailing-all',
            visible: true
          }
        ]
      },
      {
        title: 'Zarządzanie zmiennymi',
        visible: authUtils.requireRoles(['admin', 'internal']),
        value: 'properties',
        icon: 'mdi-help-box',
        to: '/properties',
      },
      {
        title: 'About',
        visible: true,
        value: 'about',
        icon: 'mdi-help-box',
        to: '/about'
      },
    ]
  }),

  methods: {
    getChildren(item) {
      return item.items.filter(i => i.visible);
    },

    getVisibleItems() {
      return this.items.filter(i => i.visible);
    },

    getVisibility(item) {
      return item.visible
    },

    emptyItems(item) {
      return !item.items
    }
  }
}
</script>
