<template>
  <div class="mainDiv">
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <test-mail-dialog :model-value="testMailTriggered"></test-mail-dialog>
    <v-snackbar
        v-model="publishedSuccess"
        :timeout="2000"
    >
      Opublikowano wzór
      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="publishedSuccess = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <image-selection-dialog :model-value="imageSelectionDialog"></image-selection-dialog>

    <v-container>
      <v-row>
        <v-col>
          <v-text-field
              v-model="template.name"
              label="Nazwa wzoru"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
              v-model="template.type"
              :items="emailTypes"
              label="Typ wzoru"
          ></v-select>
        </v-col>
        <v-col>
          <v-btn v-on:click="saveDesign">Zapisz wzór</v-btn>
        </v-col>
        <v-col>
          <v-btn v-on:click="publishDesign">Opublikuj wzór</v-btn>
        </v-col>
        <v-col>
          <v-btn v-on:click="openTestEmailDialog">Wyślij testowy mail</v-btn>
        </v-col>
        <v-col>
          <v-btn v-on:click="exportHtml">Ekspotruj do HTML</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="designEdit">
      <div class="container">
        <EmailEditor editorId="editor-1" ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady"/>
      </div>
    </div>
  </div>
</template>

<script>

import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import TestMailDialog from "@/components/mails/TestMailDialog";
import ImageSelectionDialog from "@/components/mails/ImageSelectionDialog";
import {mailsApiClient} from "@/api/mails.api.client";
import {EmailEditor} from "vue-email-editor/src/components";

export default {
  name: 'EditMailDesignerView',

  components: {
    ImageSelectionDialog,
    EmailEditor, SpinnerComponent, ErrorDialogComponent,
    TestMailDialog
  },

  props: {
    templateProp: Object
  },

  data() {
    return {
      // Email types
      emailTypes: [],

      template: {},

      // MISC
      loading: false,
      testMailTriggered: {
        templateId: null,
        visible: false
      },
      errorDialog: {
        errorMessage: "",
        visible: false
      },
      publishedSuccess: false,
      imageSelectionDialog: {
        visible: false,
        doneCallback: undefined
      }
    }
  },

  created() {
    this.template = this.templateProp;
    this.loading = true;
    this.getEmailTypes();
  },

  methods: {
    getEmailTypes() {
      mailsApiClient.getEmailTypes((response) => {
        this.emailTypes = response.data;
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd pobierania typów emailów!";
      }, () => {})
    },

    editorReady() {
      this.loading = false;
      this.$refs.emailEditor.editor.registerCallback('image', (data, done) => {
        this.imageSelectionDialog.doneCallback = done;
        this.imageSelectionDialog.visible = true;
      })
    },
    editorLoaded() {
      this.$refs.emailEditor.editor.loadDesign(this.template.design);
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        console.log('exportHtml', data);
      });
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign(
          (design) => {
            this.loading = true;
            this.template.design = design;
            mailsApiClient.updateTemplate(this.template.id, this.template,
                () => {
                  //todo snackbrar
                }, () => {
                  this.errorDialog.visible = true;
                  this.errorDialog.errorMessage = "Błąd podczas modyfikowania wzoru!";
                }, () => this.loading = false
            )
          }
      )
    },
    publishDesign() {
      this.loading = true;
      try {
        this.$refs.emailEditor.editor.exportHtml(
            (data) => {
              mailsApiClient.publishTemplate(this.template.id, {'html': data.html},
                  () => {
                    this.publishedSuccess = true;
                  }, () => {
                    this.errorDialog.visible = true;
                    this.errorDialog.errorMessage = "Błąd podczas publikacji wzoru!";
                  }, () => this.loading = false
              )
            }
        )
      } finally {
        this.loading = false;
      }
    },

    openTestEmailDialog() {
      this.testMailTriggered.visible = true;
      this.testMailTriggered.templateId = this.template.id;
    }
  }
};
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.mainDiv {
  height: 100%;
}

#app, .mainDiv .designEdit {
  height: 100%;
}

.mainDiv .designEdit .container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

#bar {
  flex: 1;
  background-color: #40B883;
  color: #FFF;
  padding: 10px;
  display: flex;
  max-height: 40px;
}

#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}

#bar button {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #000;
  color: #FFF;
  border: 0px;
  max-width: 150px;
  cursor: pointer;
}

#bar a {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border: 0px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  line-height: 160%;
}
</style>