<template>
  <v-card>
    <v-card-title>
      Gotowe pliki
    </v-card-title>
    <v-data-table
        class="elevation-1"
        :headers="sourceFiles.headers"
        :items="sourceFiles.parsedFiles"
    >
      <template v-slot:[`item.filename`]="{ item }">
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="previewGeneratedPDF(item.fileId)"
            >
              {{ item.filename }}
            </v-btn>
          </template>
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  icon
                  dark
                  @click="handleDocumentClosed()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                  icon
                  dark
                  @click="downloadGeneratedFile(item.fileId, item.filename)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-toolbar>
            <vue-pdf-embed
                :source="pdfSource"
                @rendered="handleDocumentRender"
            />
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:top>
        <v-file-input
            label="Wgraj pliki"
            multiple
            @change="uploadGeneratedFiles($event)"
        ></v-file-input>
        <v-btn
            @click="downloadGeneratedFileAsZip()"
        >
          Pobierz jako Zip
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
              Akcje
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                    @click="downloadGeneratedFile(item.fileId, item.filename)"
                >
                  Ściągnij
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                    @click="deleteGeneratedFile(item.fileId)"
                >
                  Usuń
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import {saveAs} from "file-saver";
import {ORDERS_URL} from "@/config/api.config";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {entitiesApiClient} from "@/api/entities.api.client";

export default {
  name: 'GeneratedFilesDetails',
  inheritAttrs: false,
  components: {
    VuePdfEmbed
  },
  props: {
    orderId: String,
    entityId: String,
    errorDialog: {},
    loading: Boolean
  },

  data() {
    return {
      sourceFiles: {
        headers: [
          {text: "Id", value: 'fileId'},
          {text: "Nazwa", value: 'filename'},
          {text: "Dodano", value: 'added'},
          {text: "Akcje", sortable: false, value: 'actions'}
        ],
        parsedFiles: []
      },
      pdfSource: '',
      dialog: false,
    }
  },

  created() {
    this.getSources();
  },

  methods: {
    getSources() {
      entitiesApiClient.getEntityGeneratedFiles(this.orderId, this.entityId, (response) => {
        this.sourceFiles.parsedFiles = response.data.map(file => {
          return {
            fileId: file.id,
            filename: file.filename,
            added: file.added,
            link: ORDERS_URL + `/${this.orderId}/entities/${this.entityId}/files/generated/${file.id}`
          }
        });
      }, (error) => {
        console.error(error);
      }, () => {
      })
    },

    previewGeneratedPDF(fileId) {
      entitiesApiClient.getEntityGeneratedFileAsBase64(this.orderId, this.entityId, fileId, (response) => {
        this.pdfSource = response.data;
      }, (error) => {
        console.error(error);
      }, () => {
      })
    },

    handleDocumentRender() {
      this.isLoading = false
    },

    handleDocumentClosed() {
      this.dialog = false;
      this.isLoading = false
    },

    downloadGeneratedFile(fileId, filename) {
      entitiesApiClient.getEntityGeneratedFile(this.orderId, this.entityId, fileId, (response) => {
        if (filename !== undefined) {
          saveAs(response.data, filename);
        } else {
          saveAs(response.data);
        }
      }, (error) => {
        console.error(error);
      }, () => {
      })
    },

    downloadGeneratedFileAsZip() {
      entitiesApiClient.getEntityGeneratedFilesAsZip(this.orderId, this.entityId, (response) => {
        saveAs(response.data, this.entityId + '.zip');
      }, (error) => {
        console.error(error);
      }, () => {
      })
    },

    uploadGeneratedFiles(files) {
      const completion = [];

      for (let i = 0; i < files.length; i++) {
        completion.push(this.uploadGeneratedFile(files[i]))
      }
      Promise.all(completion)
          .then(() => this.getSources())
          .catch(error => console.error(error))
          .finally(() => {
          })
    },
    uploadGeneratedFile(file) {
      const formData = new FormData();
      formData.append("file", file);
      return entitiesApiClient.postEntityGeneratedFile(this.orderId, this.entityId, formData, () => {
      }, (error) => {
        console.error(error);
      }, () => {
      })
    },

    deleteGeneratedFile(fileId) {
      entitiesApiClient.deleteEntityGeneratedFile(this.orderId, this.entityId, fileId, () => {
        this.getSources();
      }, (error) => {
        console.error(error);
      }, () => {
      })
    }
  },
};
</script>
