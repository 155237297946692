import {default as axios} from "axios";
import {INVOICES_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class InvoiceApiClient {
    getInvoice(orderId, successCallback, errorCallback, finallyCallback) {
        axios.post(INVOICES_URL + `/order/${orderId}`, null, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    downloadInvoicesAsZip(data, successCallback, errorCallback, finallyCallback) {
        axios.post(INVOICES_URL + '/zip', data, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN),
                'Accept': 'application/zip'
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }
}

export const invoiceApiClient = new InvoiceApiClient();