<template>
  <div>
    <spinner-component :loaded="loading"></spinner-component>
    <v-container>
      <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
      <v-row>
        <v-col>
          <v-stepper v-model="voucherCreationStep">
            <v-banner
                disabled
            >
              <i
                  style="color: grey"
              >Aby stworzyć pojedynczy kod rabatowy wielokrotnego użytku, należy zostawić ilość kodów jako 1
                oraz wpisać w pozycji POSTFIX kod, który będzie używany przez klientów.<br>
                W takim przypadku parametry PREFIX oraz DŁUGOŚC KODU nie są brane pod uwage.<br>
                Tak stworzony kod będzie kodem wielokrotnego użytku (zaleca się, aby ustawić limit czasowy na
                takim kodzie).
              </i>
            </v-banner>
            <v-stepper-header>
              <v-stepper-step
                  :complete="voucherCreationStep > 1"
                  step="1"
              >
                Podstawowe dane
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                  :complete="voucherCreationStep > 2"
                  step="2"
              >
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                  :complete="voucherCreationStep > 3"
                  step="3"
              >
                Warunki
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4">
                Limity
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card>
                  <voucher-base-add-details-component :model-value="voucher"></voucher-base-add-details-component>
                  <v-card-actions>
                    <v-btn
                        color="primary"
                        @click="moveToStep(2)"
                    >
                      Następny krok
                    </v-btn>

                    <v-btn
                        text
                        @click="backToList"
                    >
                      Wróć do listy kodów
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </v-stepper-content>

              <v-stepper-content step="2">

                <v-card>
                  <percentage-voucher-type :model-value="voucher.parameters"
                                           v-if="voucher.type === 'PERCENTAGE'"></percentage-voucher-type>
                  <value-voucher-type :model-value="voucher.parameters"
                                      v-if="voucher.type === 'VALUE'"></value-voucher-type>
                  <progressive-book-count-voucher-type :model-value="voucher.parameters"
                                                       type="PROGRESSIVE_BOOK_COUNT"
                                                       v-if="voucher.type === 'PROGRESSIVE_BOOK_COUNT'">
                  </progressive-book-count-voucher-type>
                  <div v-if="!parametersTypes.includes(voucher.type)">
                    Brak parametrów
                  </div>
                  <v-card-actions>
                    <v-btn
                        color="primary"
                        @click="moveToStep(3)"
                    >
                      Następny krok
                    </v-btn>

                    <v-btn
                        text
                        @click="moveToStep(1)"
                    >
                      Poprzedni krok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card>
                  <generic-conditions v-if="conditionalTypes.includes(voucher.type)"
                                      :model-value="voucher"></generic-conditions>
                  <div v-if="!conditionalTypes.includes(voucher.type)">
                    Brak warunków do podania
                  </div>
                  <v-card-actions>
                    <v-btn
                        color="primary"
                        @click="moveToStep(4)"
                    >
                      Następny krok
                    </v-btn>

                    <v-btn
                        text
                        @click="moveToStep(2)"
                    >
                      Poprzedni krok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-card>
                  <generic-limits :model-value="voucher"></generic-limits>
                  <v-card-actions>
                    <v-btn
                        color="primary"
                        @click="saveVoucher()"
                    >
                      Zapisz kod rabatowy
                    </v-btn>

                    <v-btn
                        text
                        @click="moveToStep(3)"
                    >
                      Poprzedni krok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {vouchersApiClient} from "@/api/vouchers.api.client";
import ErrorDialogComponent from "@/components/ErrorDialogComponent.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import VoucherBaseAddDetailsComponent from "@/components/vouchers/add/VoucherBaseAddDetails.vue";
import GenericConditions from "@/components/vouchers/add/conditions/GenericConditions.vue";
import GenericLimits from "@/components/vouchers/add/limits/GenericLimits.vue";
import PercentageVoucherType from "@/components/vouchers/PercentageVoucherType.vue";
import ValueVoucherType from "@/components/vouchers/ValueVoucherType.vue";
import ProgressiveBookCountVoucherType from "@/components/vouchers/ProgressiveBookCountVoucherType.vue";

export default {
  name: 'AddNewVoucherView',
  inheritAttrs: false,
  components: {
    ProgressiveBookCountVoucherType,
    ValueVoucherType,
    PercentageVoucherType,
    GenericLimits,
    GenericConditions, VoucherBaseAddDetailsComponent, SpinnerComponent, ErrorDialogComponent
  },

  data() {
    return {
      voucherCreationStep: 1,

      voucher: {
        active: false,
        name: '',
        prefix: '',
        postfix: '',
        type: '',
        length: 6,
        groupType: this.$route.params.groupType,
        amountOfCodes: 1,
        parameters: {},
        limitations: {},
        conditions: {
          allCondition: {}
        }
      },

      parametersTypes: ['PERCENTAGE', 'VALUE', 'PROGRESSIVE_BOOK_COUNT'],
      conditionalTypes: ['PERCENTAGE', 'VALUE', 'FREE_DEDICATION', 'PROGRESSIVE_BOOK_COUNT'],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  methods: {
    moveToStep(stepNumber) {
      this.voucherCreationStep = stepNumber;
    },

    backToList() {
      this.$router.push({name: 'vouchers-group', params: {groupType: this.$route.params.groupType}});
    },

    saveVoucher() {
      this.loading = true;
      const voucherToAdd = {
        active: this.voucher.active,
        name: this.voucher.name,
        prefix: this.voucher.prefix,
        postfix: this.voucher.postfix,
        length: this.voucher.length,
        type: this.voucher.type,
        groupType: this.voucher.groupType,
        amountOfCodes: this.voucher.amountOfCodes,
        parameters: this.voucher.parameters,
        limitations: this.voucher.limitations,
        conditions: this.voucher.conditions,
        codesFromFile: this.voucher.codesFromFile
      }

      vouchersApiClient.addVoucher(voucherToAdd, () => {
        this.$router.push({name: 'vouchers-group', params: {'groupType': this.$route.params.groupType}});
      }, (error) => {
        this.snackbar = true;
        this.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    }
  },
};
</script>
