<template>
  <div>
    <spinner-component :loaded="loading"></spinner-component>

    <v-container>
      <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
      <v-col>
        <v-btn
            block
            color="primary"
            elevation="1"
            @click="createNewVoucherGroup"
        >
          Dodaj nowy kod rabatowy
        </v-btn>
      </v-col>
      <v-row>
        <v-col>
          <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="parsedVoucherGroups"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    Akcje
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                        color="primary"
                        elevation="1"
                        @click="viewVoucherCodes(item.id)"
                    >
                      Kody
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                        color="primary"
                        elevation="1"
                        @click="editVoucherGroup(item.id)"
                    >
                      Edytuj
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                        color="primary"
                        elevation="1"
                        @click="deleteVoucher(item)"
                    >
                      Usuń
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>


import {vouchersApiClient} from "@/api/vouchers.api.client";
import ErrorDialogComponent from "@/components/ErrorDialogComponent.vue";

export default {
  name: 'VouchersView',
  inheritAttrs: false,
  components: {ErrorDialogComponent},

  data() {
    return {
      voucherGroups: [],
      headers: [
        {text: "Id", value: 'id'},
        {text: "Grupa", value: 'groupType'},
        {text: "Aktywny", value: 'active'},
        {text: "Nazwa", value: 'name'},
        {text: "Type", value: 'type'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],
      parsedVoucherGroups: [],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.$watch(
        () => {
          return this.$route.params.groupType;
        },
        () => {
          this.parsedVoucherGroups = [];
          this.getVouchers(this.$route.params.groupType)
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },

  methods: {
    getVouchers(groupType) {
      this.loading = true;
      vouchersApiClient.getVoucherGroupsByGroupName(groupType,
          response => {
            this.voucherGroups = response.data;
            this.voucherGroups.forEach(group => {
              this.parsedVoucherGroups.push({
                id: group.id,
                groupType: group.groupType,
                active: group.active,
                name: group.name,
                type: group.type
              })
            })
          },
          error => {
            console.error(error);
          }, () => this.loading = false);
    },

    createNewVoucherGroup() {
      this.$router.push({name: 'new-voucher', params: {groupType: this.$route.params.groupType}});
    },

    editVoucherGroup(id) {
      this.$router.push({name: 'edit-voucher', params: {groupType: this.$route.params.groupType, 'id': id}});
    },

    viewVoucherCodes(id) {
      this.$router.push({name: 'vouchers-codes', params: {groupType: this.$route.params.groupType, 'id': id}});
    },

    deleteVoucher(item) {
      this.loading = true;
      vouchersApiClient.deleteVoucherGroup(item.id, () => {
        this.parsedVoucherGroups = [];
        this.getVouchers(this.$route.params.groupType);
      }, (error) => {
        console.error(error);
      }, () => this.loading = false);
    }
  }
}
</script>
