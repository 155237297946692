<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-card>
      <v-card-title>
        Zmówione książeczki
      </v-card-title>
      <v-card-text>
        <v-expansion-panels class="my-4">

          <v-expansion-panel v-for="entity of order.orderEntities" :key="entity.id">
            <v-expansion-panel-header>
              <j-w-b-order-entities-details
                  v-if="entity.product.type === 'JWB'"
                  :manual-statuses="manualStatuses"
                  :order="order"
                  :entity-model="entity"
              ></j-w-b-order-entities-details>
              <b-h-order-entities-details
                  v-if="entity.product.type === 'BH'"
                  :automatic-statuses="automaticStatuses"
                  :order="order"
                  :entity-model="entity"
              ></b-h-order-entities-details>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <user-images-details :order-id="order.id" :entity-id="entity.id"></user-images-details>
              <v-divider></v-divider>
              <generated-files-details
                  :order-id="order.id"
                  :entity-id="entity.id"
              ></generated-files-details>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {computed} from "vue";
import GeneratedFilesDetails from "@/components/orders/entities/GeneratedFilesDetails";
import UserImagesDetails from "@/components/orders/entities/UserImagesDetails";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import JWBOrderEntitiesDetails from "@/components/orders/entities/jwb/JWBOrderEntitiesDetails";
import BHOrderEntitiesDetails from "@/components/orders/entities/bh/BHOrderEntitiesDetails";
import {entitiesApiClient} from "@/api/entities.api.client";

export default {
  name: 'OrderEntitiesDetails',
  inheritAttrs: false,
  components: {
    JWBOrderEntitiesDetails,
    BHOrderEntitiesDetails,
    UserImagesDetails,
    GeneratedFilesDetails,
    SpinnerComponent,
    ErrorDialogComponent
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    order() {
      return this.modelValue ? this.modelValue : {}
    },
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      automaticStatuses: [],
      manualStatuses: [],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.getAutomaticStatuses();
    this.getManualStatuses();
  },

  methods: {
    getAutomaticStatuses() {
      this.getEntityStatuses("BH", (data) => {
        this.automaticStatuses = data;
      });
    },

    getManualStatuses() {
      this.getEntityStatuses("JWB", (data) => {
        this.manualStatuses = data;
      });
    },

    getEntityStatuses(productType, callback) {
      entitiesApiClient.getOrderEntityStatuses(productType, (response) => {
            callback(response.data);
          },
          error => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = error.response.data.detail;
          }, () => this.loading = false);
    }
  }
};
</script>
