import {default as axios} from "axios";
import {PRODUCTS_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";
import qs from "qs";

export class ProductApiClient {
    saveProduct(productData, successCallback, errorCallback, finallyCallback) {
        axios.post(PRODUCTS_URL, productData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => {
            finallyCallback();
        });
    }

    uploadProductImages(id, formData, successCallback, errorCallback, finallyCallback) {
        axios.post(PRODUCTS_URL + `/${id}/images`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(() => {
            successCallback()
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getProducts(successCallback, errorCallback, finallyCallback = () => undefined) {
        axios.get(PRODUCTS_URL, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getInternalListing(filter, successCallback, errorCallback, finallyCallback = () => {}) {
        axios.get(PRODUCTS_URL + '/internal', {
            params: {
                statuses: filter.statuses,
                categories: filter.categories,
                types: filter.types
            },
            paramsSerializer: params => {
                return qs.stringify(params, {indices: false})
            },
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    removeProduct(product, successCallback, errorCallback, finallyCallback) {
        axios.delete(PRODUCTS_URL + `/${product.id}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getProductInternal(productId, successCallback, errorCallback, finallyCallback) {
        axios.get(PRODUCTS_URL + `/${productId}/internal`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getProductImages(productId, successCallback, errorCallback, finallyCallback) {
        axios.get(PRODUCTS_URL + `/${productId}/images`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getProductPreviewImages(productId, successCallback, errorCallback, finallyCallback) {
        axios.get(PRODUCTS_URL + `/${productId}/images/preview`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    updateProductImageMetadata(productId, imageId, body, successCallback, errorCallback, finallyCallback) {
        axios.put(PRODUCTS_URL + `/${productId}/images/${imageId}`, body, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    removeProductImage(productId, imageId, successCallback, errorCallback, finallyCallback) {
        axios.delete(PRODUCTS_URL + `/${productId}/images/${imageId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    editProduct(productId, productData, successCallback, errorCallback, finallyCallback) {
        axios.put(PRODUCTS_URL + `/${productId}`, productData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => {
            finallyCallback();
        });
    }

    invalidateCache(successCallback, errorCallback, finallyCallback) {
        axios.put(PRODUCTS_URL + `/invalidate/cache`, {}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => {
            finallyCallback();
        });
    }

    importProducts(formData, successCallback, errorCallback, finallyCallback) {
        axios.post(PRODUCTS_URL + `/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => {
            finallyCallback();
        });
    }

    exportProducts(ids, successCallback, errorCallback, finallyCallback) {
        axios.post(PRODUCTS_URL + `/export`, ids, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN),
                'Accept': 'application/zip'
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => {
            finallyCallback();
        });
    }
}

export const productApiClient = new ProductApiClient();