<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-dialog
        v-model="imageSelectionDialog.visible"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Wybierz zdjęcie</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-container>
              <v-row>
                <v-col>
                  <v-file-input
                      v-model="imageToUpload"
                      label="Wgraj plik"
                      accept="image/*"
                      required
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-btn
                      dark
                      text
                      @click="addImage"
                      v-if="imageToUpload !== undefined"
                  >
                    Dodaj zdjęcie
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
                v-for="image in images"
                :key="image.link"
                class="d-flex child-flex"
                cols="4"
            >
              <v-card>
                <v-card-text>
                  <v-img
                      :src="image.link"
                      :lazy-src="image.link"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      text
                      tile
                      @click="selectImage(image)"
                  >
                    Wybierz
                  </v-btn>
                  <v-btn
                      text
                      tile
                      @click="removeImage(image)"
                  >
                    Usuń
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {MAIL_TEMPLATES_URL} from "@/config/api.config";
import {computed} from "vue";
import {mailsApiClient} from "@/api/mails.api.client";

export default {
  name: 'ImageSelectionDialog',
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    }
  },

  computed: {
    imageSelectionDialog() {
      return this.modelValue ? this.modelValue : false
    }
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      },
      selectImageDialog: false,
      images: [],
      imageToUpload: undefined,
    }
  },

  created() {
    this.getImages()
  },

  methods: {
    selectImage(image) {
      image.callback();
      this.imageSelectionDialog.visible = false;
    },

    getImages() {
      this.loading = true;
      mailsApiClient.getTemplateImages((response) => {
            this.images = response.data.map(image => {
              return {
                id: image.id,
                link: MAIL_TEMPLATES_URL + `/images/${image.id}`,
                callback: () => this.imageSelectionDialog.doneCallback({progress: 100, url: MAIL_TEMPLATES_URL + `/images/${image.id}`})
              }
            })
          }, () => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Błąd podczas pobierania obrazów!";
          }, () => this.loading = false
      )
    },

    addImage() {
      this.loading = true;
      const formData = new FormData();
      formData.set('image', this.imageToUpload);
      mailsApiClient.uploadTemplateImage(formData, () => {
            this.imageToUpload = undefined;
            this.getImages();
          }, () => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Błąd podczas dodawania obrazu!";
          }, () => this.loading = false
      )
    },

    removeImage(image) {
      this.loading = true;
      mailsApiClient.deleteTemplateImage(image.id, () => {
            this.getImages();
          }, () => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Błąd podczas dodawania obrazu!";
          }, () => this.loading = false
      )
    },

    closeDialog() {
      this.imageSelectionDialog.visible = false;
    }
  }
};
</script>
