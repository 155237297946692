import {default as axios} from "axios";
import {MAIL_TEMPLATES_URL, MAIL_URL, MAILING_LISTS_URL, NEWSLETTER_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class MailsApiClient {
    getNewsletters(successCallback, errorCallback, finallyCallback) {
        axios.get(MAIL_TEMPLATES_URL, {
            params: {
                types: "NEWSLETTER"
            },
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    getTemplates(successCallback, errorCallback, finallyCallback = () => {}) {
        axios.get(MAIL_TEMPLATES_URL, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    importEmailTemplates(formData, successCallback, errorCallback, finallyCallback) {
        axios.post(MAIL_URL + '/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    getEmailTypes(successCallback, errorCallback, finallyCallback) {
        axios.get(MAIL_URL + '/types', {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    saveTemplate(data, successCallback, errorCallback, finallyCallback) {
        axios.post(MAIL_TEMPLATES_URL, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    saveNewsletter(data, successCallback, errorCallback, finallyCallback) {
        axios.post(NEWSLETTER_URL, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    updateTemplate(templateId, data, successCallback, errorCallback, finallyCallback) {
        axios.put(MAIL_TEMPLATES_URL + `/${templateId}`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    updateNewsletter(newsletterId, data, successCallback, errorCallback, finallyCallback) {
        axios.put(NEWSLETTER_URL + `/${newsletterId}`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    publishTemplate(templateId, htmlData, successCallback, errorCallback, finallyCallback) {
        axios.put(MAIL_TEMPLATES_URL + `/${templateId}/publish`, htmlData, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    removeTemplate(templateId, successCallback, errorCallback, finallyCallback) {
        axios.delete(MAIL_TEMPLATES_URL + `/${templateId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(() => {
            successCallback();
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    getTemplateImages(successCallback, errorCallback, finallyCallback) {
        axios.get(MAIL_TEMPLATES_URL + '/images', {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    uploadTemplateImage(data, successCallback, errorCallback, finallyCallback) {
        axios.post(MAIL_TEMPLATES_URL + '/images', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    deleteTemplateImage(id, successCallback, errorCallback, finallyCallback) {
        axios.delete(MAIL_TEMPLATES_URL + `/images/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    sendTestEmail(data, successCallback, errorCallback, finallyCallback) {
        axios.post(MAIL_URL + '/test', data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    sendNewsletter(newsletterId, data, successCallback, errorCallback, finallyCallback) {
        axios.post(NEWSLETTER_URL + `/send/${newsletterId}`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    getAddresses(successCallback, errorCallback, finallyCallback) {
        axios.get(MAILING_LISTS_URL, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    uploadAddresses(data, successCallback, errorCallback, finallyCallback) {
        axios.post(MAILING_LISTS_URL, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }

    deleteEmailAddresses(data, successCallback, errorCallback, finallyCallback) {
        axios.post(MAILING_LISTS_URL + '/delete', data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error);
        }).finally(() => {
            finallyCallback();
        });
    }
}

export const mailsApiClient = new MailsApiClient();