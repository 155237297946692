<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-card>
      <v-card-title>
        Dane do wysyłki
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  label="Imię"
                  v-model="shipment.name"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Nazwisko"
                  v-model="shipment.surname"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Ulica"
                  v-model="shipment.street"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Numer domu"
                  v-model="shipment.houseNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="2" v-if="shipment.aptNumber">
              <v-text-field
                  :disabled="!edit"
                  label="Numer lokalu"
                  v-model="shipment.aptNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Kod pocztowy"
                  v-model="shipment.zipCode"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Miasto"
                  v-model="shipment.city"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Kraj"
                  v-model="shipment.country"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Adres email"
                  v-model="shipment.email"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Numer telefonu"
                  v-model="shipment.phone"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  disabled
                  label="Sposób wysyłki"
                  v-model="courier.name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  disabled
                  label="Kraj docelowy"
                  :value="orderCourier.shipmentZone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  disabled
                  label="Numer paczki"
                  :value="orderCourier.packageNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  disabled
                  label="Paczkomat docelowy"
                  :value="orderCourier.targetPoint"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="edit" @click="edit = true">Edytuj</v-btn>
        <v-btn :disabled="!edit" @click="save()">Zapisz</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {computed} from "vue";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {ordersApiClient} from "@/api/orders.api.client";

export default {
  name: 'OrderShipmentDetails',
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    orderCourier: {
      type: Object,
      default: () => ({}),
    },
    shipmentMethods: undefined,

    orderId: String
  },
  computed: {
    shipment() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      edit: false,
      courier: undefined,

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false,
        callback: undefined
      }
    }
  },

  created() {
    this.courier = this.shipmentMethods.find(method => method.id === this.orderCourier.shipmentMethodId);
  },

  methods: {
    save() {
      this.loading = true;
      ordersApiClient.updateShipmentData(this.orderId, this.shipment, () => {
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
        this.errorDialog.callback = () => this.$router.go(0);
      }, () => {
        this.loading = false;
        this.edit = false;
      })
    }
  }
};
</script>
