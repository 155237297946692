<template>
  <div>
    <v-card class="my-3">
      <v-card-title>Preview images</v-card-title>
      <v-card-text>
        <v-carousel>
          <v-carousel-item
              v-for="(image, i) in this.previewImages"
              :key="i"
              eager
          >
            <v-container
                style="max-width: 70%"
            >
              <v-row
              >
                <v-col>
                  <v-img
                      :src="backend + '/v1/products/' +productId + '/images/' +  image.id"
                      contain max-width="600" max-height="400"/>
                </v-col>
                <v-col>
                  <v-row>
                    <v-btn
                        outlined
                        @click="removeProductImage(image.id)"
                    >
                      Remove
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-text-field type="number" v-model="image.priority" label="Priority"></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field type="number" v-model="image.priority" label="Alt tag"></v-text-field>
                  </v-row>
                  <v-row>
                    <v-btn
                        outlined
                        @click="updateProductImageMetadata(image.id, image.priority)"
                    >
                      Save
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <v-card-actions>
        <v-file-input
            label="Dodaj zdjęcie produktowe poglądowe"
            @change="uploadProductImage($event, true)"
            outlined
        ></v-file-input>
      </v-card-actions>
    </v-card>
    <v-card class="my-3">
      <v-card-title>Images</v-card-title>
      <v-card-text>
        <v-carousel>
          <v-carousel-item
              v-for="(image, i) in this.images"
              :key="i"
              eager
          >
            <v-container
                style="max-width: 70%"
            >
              <v-row
              >
                <v-col>
                  <v-img
                      :src="backend + '/v1/products/' +productId + '/images/' +  image.id"
                      contain max-width="600" max-height="400"/>
                </v-col>
                <v-col>
                  <v-row>
                    <v-btn
                        outlined
                        @click="removeProductImage(image.id)"
                    >
                      Remove
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-text-field type="number" v-model="image.priority" label="Priority"></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field type="number" v-model="image.priority" label="Alt tag"></v-text-field>
                  </v-row>
                  <v-row>
                    <v-btn
                        outlined
                        @click="updateProductImageMetadata(image.id, image.priority)"
                    >
                      Save
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <v-card-actions>
        <v-file-input
            label="Dodaj zdjęcie produktowe"
            @change="uploadProductImage($event, false)"
            outlined
        ></v-file-input>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {productApiClient} from "@/api/product.api.client";

export default {
  name: 'ProductImageUpload',
  inheritAttrs: false,
  components: {},
  props: {
    productId: String,
  },

  data() {
    return {
      images: [],
      previewImages: [],
      backend: ''
    }
  },

  created() {
    this.backend = process.env.VUE_APP_BOOKHERO_API_PATH
    this.getProductPreviewImages();
    this.getProductImages();
  },

  methods: {
    getProductImages() {
      productApiClient.getProductImages(this.productId, (response) => {
        this.images = response.data;
      }, (error) => {
        console.error(error);
      }, () => {
      });
    },

    getProductPreviewImages() {
      productApiClient.getProductPreviewImages(this.productId, (response) => {
        this.previewImages = response.data;
      }, (error) => {
        console.error(error);
      }, () => {
      });
    },

    removeProductImage(imageId) {
      productApiClient.removeProductImage(this.productId, imageId, () => {
        this.getProductPreviewImages();
        this.getProductImages();
      }, (error) => {
        console.error('Failed to save data', error);
      }, () => {
      });
    },

    updateProductImageMetadata(imageId, priority) {
      productApiClient.updateProductImageMetadata(this.productId, imageId, {
        priority: priority
      }, () => {
        this.getProductPreviewImages();
        this.getProductImages();
      }, (error) => {
        console.error('Failed to save data', error);
      }, () => {
      });
    },

    uploadProductImage(file, preview) {
      let priority = 0;
      if (preview) {
        if (this.previewImages.length !== 0) {
          priority = Math.max(...this.previewImages.map(im => im.priority)) + 10;
        }
      } else {
        if (this.images.length !== 0) {
          priority = Math.max(...this.images.map(im => im.priority)) + 10;
        }
      }
      const formData = new FormData();
      formData.append('preview', preview);
      formData.append('priority', priority);
      formData.append('image', file);

      productApiClient.uploadProductImages(this.productId, formData, () => {
        this.getProductPreviewImages();
        this.getProductImages();
      }, (error) => {
        console.error('Failed to save data', error);
      }, () => {
      })
    },
  },
};
</script>
